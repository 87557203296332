import { z } from "zod";
import { Form } from "@remix-run/react";
import { ActionFunctionArgs, MetaFunction, redirect } from "@remix-run/node";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRemixForm } from "remix-hook-form";
import { authenticator } from "~/modules/auth/service.server";
import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { cn } from "~/utils";
import { commitSession, getSession } from "~/modules/auth/session.server";
import { useTranslation } from "react-i18next";

export const meta: MetaFunction = () => {
	return [
		{ title: "Login - Innsikt.AI" },
		{ name: "description", content: "Login to the Innsikt.AI platform." },
	];
};

const LoginSchema = z
	.object({
		email: z.string().min(1),
		password: z.string().min(1),
	})
	.required();

type LoginSchemaType = z.infer<typeof LoginSchema>;

const resolver = zodResolver(LoginSchema);

export async function action({ request }: ActionFunctionArgs) {
	const user = await authenticator.authenticate("user-pass", request, {
		failureRedirect: "/login",
	});

	const session = await getSession(request.headers.get("cookie"));
	session.set(authenticator.sessionKey, user);

	const headers = new Headers({
		"Set-Cookie": await commitSession(session),
	});

	if (!user.passwordSet) {
		return redirect("/set-password", { headers });
	}

	if (!user.age) {
		return redirect("/set-user-info", { headers });
	}

	return redirect("/", { headers });
}

const Login = () => {
	const {
		// handleSubmit,
		formState: { errors: fieldErrors },
		register,
	} = useRemixForm<LoginSchemaType>({ mode: "onSubmit", resolver });

	const { t } = useTranslation();

	return (
		<div className="p-5 md:flex-1">
			<div className="mb-4 text-center">
				<h1 className="text-2xl font-bold">{t("forms.login.title")}</h1>
				<p className="text-sm">{t("forms.login.subtitle")}</p>
			</div>
			<Form method="POST" className="flex flex-col space-y-5">
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="email"
						className={cn(
							`text-sm font-semibold ${
								fieldErrors.email && "text-red-500"
							}`
						)}
					>
						{t("forms.login.fields.username.label")}
					</label>
					<Input
						// {...register("email", { required: true })}
						name="email"
						id="email"
						placeholder={t(
							"forms.login.fields.username.placeholder"
						)}
					/>
				</div>
				<div className="flex flex-col space-y-1">
					<div className="flex items-center justify-between">
						<label
							htmlFor="password"
							className={cn(
								"text-sm font-semibold",
								fieldErrors.password && "text-red-500"
							)}
						>
							{t("forms.login.fields.password.label")}
						</label>
						<a
							href="/forgot-password"
							className="text-sm hover:underline"
						>
							{t("common.forgot_password")}
						</a>
					</div>
					<Input
						// {...register("password", { required: true })}
						name="password"
						type="password"
						id="password"
						placeholder={t(
							"forms.login.fields.password.placeholder"
						)}
					/>
				</div>
				<div>
					<Button
						type="submit"
						className="w-full rounded-md px-4 py-2 text-lg font-semibold shadow transition-colors duration-300 focus:outline-none focus:ring-4"
					>
						{t("common.Login")}
					</Button>
				</div>
			</Form>
			<div className="mt-5 flex flex-col">
				<span className="flex items-center justify-center space-x-2 text-sm">
					<a href="/request-access" className="hover:underline">
						{t("common.request_access")}
					</a>
				</span>
			</div>
		</div>
	);
};

export default Login;
